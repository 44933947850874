var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket",attrs:{"id":"up"}},[(_vm.loading)?_c('base-loader',{staticClass:"open-ticket__loader"}):_vm._e(),(_vm.isReady && !_vm.loading)?_c('div',{staticClass:"ticket-info"},[_c('plain-button',{staticStyle:{"justify-content":"left"},attrs:{"to":{ name: 'OpenTicket' },"icon":"arrow-left","color":"dim"}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]),_c('div',{staticClass:"ticket-info__title"},[_c('div',[_c('div',[_vm._v(_vm._s(`Тикет #${_vm.currentTicketInfo.id}`))]),_c('page-title',{staticClass:"ticket-info__title-name"},[_vm._v(" "+_vm._s(_vm.ticketName)+" ")]),(_vm.currentTicketInfo.info_item_name)?_c('label',[_vm._v(_vm._s(_vm.currentTicketInfo.info_item_name))]):_vm._e()],1),(_vm.getStatus)?_c('main-label',{staticClass:"ticket-info__title-status",attrs:{"color":_vm.getStatusColor}},[_vm._v(" "+_vm._s(_vm.getStatus)+" ")]):_vm._e()],1),_c('transition-group',{attrs:{"mode":"slide-fade"}},_vm._l((_vm.ticketList),function(msg){return _c('base-dialog',{key:msg.id,staticClass:"ticket-info__dialogs",attrs:{"el":_vm.lastReadMessage === msg.id ? msg.id : '',"plid":_vm.id,"elid":msg.id,"theme":_vm.currentTicketInfo.info_item_name,"name":msg.user.realname,"position":msg.user.position,"time":msg.date_post_locale,"text":msg.body,"image":_vm.srcAva(msg.type, msg.avatar),"type":msg.type,"files":msg.file,"rate":msg.rates},on:{"update-rate":_vm.onChange}})}),1),_c('send-message',{attrs:{"arhive":_vm.toArhive}}),(_vm.ticketList.length > 2)?_c('div',{staticClass:"ticket-info__up",attrs:{"id":"down"}},[_c('plain-button',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          el: '#up',
          duration: 1000,
          lazy: true,
          easing: 'ease',
          offset: -100,
        }),expression:"{\n          el: '#up',\n          duration: 1000,\n          lazy: true,\n          easing: 'ease',\n          offset: -100,\n        }"}],attrs:{"icon":"arrow-up","color":"dim"}},[_vm._v(" "+_vm._s(_vm.$t('up'))+" ")])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }